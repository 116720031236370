.summary {
  .serviceAdded {
    h3 {
      margin-bottom: 0;
    }
  }
  .busses {
    h4 {
      margin-bottom: 0;
    }
  }
  .tableColspan2 {
    @media all and (min-width: 768px) {
      width: 66.667%;
    }
  }

  .details {
    border-bottom: 1px solid #f3f2f1;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
    div {
      // every third
      &:nth-child(3n) {
        text-align: right;
      }
      // every third - starting from the second child element
      &:nth-child(3n + 2) {
        text-align: left;
      }
    }
  }
}
